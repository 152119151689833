import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { getRemoteConfigurations } from '@/firebase/firebase';
import { IAdSpace } from '@/types/Ads';
import adsJson from './results.json';

interface AdContextState {
  audience:
    | 'just-diagnosed'
    | 'those-around-mser'
    | 'im-experienced-with-ms'
    | 'i-might-have-ms'
    | 'global';
  spaces: IAdSpace[];
  sidebarSpace: IAdSpace | null;
}

const defaultContextValue: AdContextState = {
  audience: 'global',
  spaces: [],
  sidebarSpace: null,
};

export const AdContext = createContext(defaultContextValue);

const AdProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [spaces, setSpaces] = useState<IAdSpace[]>([]);
  const [sidebarSpace, setSidebarSpace] = useState<IAdSpace | null>(null);
  const [audience, setAudience] =
    useState<AdContextState['audience']>('global');

  useEffect(() => {
    const fetchAds = () => {
      setSpaces(adsJson as IAdSpace[]);
      const sidebarSpaces = adsJson.filter(item => !!item.sidebar_ad);
      if (sidebarSpaces.length) {
        setSidebarSpace(sidebarSpaces[0] as IAdSpace);
      }
    };

    getRemoteConfigurations().then(remoteConfig => {
      if (remoteConfig) {
        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
        remoteConfig.defaultConfig = {
          audience: 'global',
        };

        fetchAndActivate(remoteConfig)
          .then(() => {
            const audienceRemoteConfig = getValue(
              remoteConfig,
              'audience',
            ).asString() as AdContextState['audience'];
            if (audienceRemoteConfig) {
              setAudience(audienceRemoteConfig);
              fetchAds();
            }
          })
          .catch(err => {
            console.error('Fetch spaces error', err);
          });
      }
    });
  }, [setSpaces, setAudience]);

  const contextValue = useMemo(
    () => ({
      audience,
      spaces,
      sidebarSpace,
    }),
    [audience, spaces, sidebarSpace],
  );

  return (
    <AdContext.Provider value={contextValue}>{children}</AdContext.Provider>
  );
};

export const useAds = (): AdContextState => {
  const context = useContext(AdContext);
  if (context === undefined) {
    throw new Error('useAds can only be used inside AdProvider');
  }
  return context;
};

AdProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdProvider;
